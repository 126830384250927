import React from "react";
import { MainContent } from "layout/MainContent";


interface LayoutProps {
  children: React.ReactNode
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex bg-appBg text-primary h-[100vh] font-lato">
      <MainContent>
        {children}
      </MainContent>
    </div>
  );
}
