import React from "react";
import { useLocation } from "react-router-dom"; // Add this import
import { Breadcrumb } from "theplatform/breadcrumbs/Breadcrumb";

interface BreadcrumbsProps {
  capitalizeAll?: boolean
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ capitalizeAll = false }) => {
  const location = useLocation();

  const path = location.pathname.split("/").filter(Boolean);

  return (
    <div className="flex gap-4 text-accent">
      {path.map((label, idx) => (
        <Breadcrumb key={idx} label={label} isFirst={idx === 0} isLast={idx === path.length - 1} capitalizeAll={capitalizeAll} />
      ))}
    </div>
  );
}
