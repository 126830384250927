'use client';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import useSWR from 'swr';
import { apiFetcher } from 'utils';
import { Response, Technique } from 'components/interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConditionalLink } from "theplatform/conditionalLink";

export const ItemsTable = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Populate Selects
  const mentorsResponse = useSWR<Response<any>>('https://api.airtable.com/v0/appfIqZsH6x67Djws/mentor', apiFetcher)
  const mentors = mentorsResponse.data?.records || []

  const categoryResponse = useSWR<Response<any>>('https://api.airtable.com/v0/appfIqZsH6x67Djws/category', apiFetcher)
  const categories = categoryResponse.data?.records || []

  // Filter results
  let techniqueFilters = [] as string[];

  const mentorFilter = searchParams.get("mentor")
  if (mentorFilter) {
    techniqueFilters.push(`{mentor.name}="${mentorFilter}"`)
  }

  const categoryFilter = searchParams.get("category")
  if (categoryFilter) {
    techniqueFilters.push(`{category.name}="${categoryFilter}"`)
  }

  const optionalFilter = techniqueFilters.length ?
    "?filterByFormula=" + encodeURIComponent(`AND(${techniqueFilters.join(",")})`)
    : "";

  const response = useSWR<Response<Technique>>(`https://api.airtable.com/v0/appfIqZsH6x67Djws/technique${optionalFilter}`, apiFetcher)
  const techniques = response.data?.records || []

  // Methods
  const onMentorChange = (value: any) => {
    const params = new URLSearchParams(searchParams.toString());
    if (value !== "all") {
      params.set("mentor", value);
    } else {
      params.delete("mentor");
    }
    navigate(`/tips?${params.toString()}`);
  }

  const onCategoryChange = (value: any) => {
    const params = new URLSearchParams(searchParams.toString());
    if (value !== "all") {
      params.set("category", value);
    } else {
      params.delete("category");
    }
    navigate(`/tips?${params.toString()}`);
  }

  return (
    <div className="flex flex-col gap-4">
      {/* Filters */}
      <div className="flex gap-4 w-full flex-col md:flex">
        <div className="flex flex-col gap-1 md:min-w-72">
          <div className="text-zinc-800">Filter by Category</div>
          <Select onValueChange={onCategoryChange} value={categoryFilter || ""}>
            <SelectTrigger className="w-[180px] bg-zinc-800 border-zinc-700 text-zinc-100">
              <SelectValue placeholder="All" />
            </SelectTrigger>
            <SelectContent className="bg-zinc-800 border-zinc-700">
              <SelectItem value="all" className="text-zinc-100">All</SelectItem>
              {categories.map(category => (
                <SelectItem key={category.id} value={category.fields.name} className="text-zinc-100">{category.fields.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-1 md:min-w-72">
          <div className="text-zinc-800">Filter by Mentor</div>
          <Select onValueChange={onMentorChange} value={mentorFilter || ""}>
            <SelectTrigger className="w-[180px] bg-zinc-800 border-zinc-700 text-zinc-100">
              <SelectValue placeholder="All" />
            </SelectTrigger>
            <SelectContent className="bg-zinc-800 ">
              <SelectItem value="all" className="text-zinc-100">All</SelectItem>
              {mentors.map(mentor => (
                <SelectItem key={mentor.id} value={mentor.fields.Name} className="text-zinc-100">{mentor.fields.Name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="bg-zinc-900 text-zinc-100 p-6 rounded-lg flex flex-col">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-zinc-700">
              <th className="py-2 text-zinc-400 w-6/12">Technique</th>
              <th className="py-2 text-zinc-400 w-3/12">Category</th>
              <th className="py-2 text-zinc-400 w-3/12">Mentor</th>
            </tr>
          </thead>
          <tbody>
            {techniques.map((technique, index) => (
              <tr
                key={technique.id}
                className={`
                ${index < techniques.length - 1 ? "border-b border-zinc-800" : ""}
                hover:bg-zinc-800 transition-colors duration-200
              `}
              >
                <td className="py-3 text-zinc-100 w-7/12">{technique.fields.description}</td>
                <td className="py-3 w-2/12">
                  <div>
                    {technique.fields["category.name"].map((technique, idx) => (
                      <ConditionalLink key={idx}>
                        <span onClick={() => onCategoryChange(technique)}
                          className="inline-block px-2 py-1 rounded text-sm mr-1 mb-1 hover:underline bg-zinc-700 text-zinc-100 cursor-pointer"
                        >
                          {technique}
                        </span>
                      </ConditionalLink>
                    ))}
                  </div>
                </td>
                <td className="py-3 w-3/12">
                  <ConditionalLink>
                    <span
                      onClick={() => onMentorChange(technique.fields["mentor.name"])}
                      className={`inline-block px-2 py-1 rounded text-sm mr-1 mb-1 cursor-pointer ${technique.fields["mentor.colors"][0]} hover:underline`}
                    >
                      {technique.fields["mentor.name"]}
                    </span>
                  </ConditionalLink>
                </td>
                {/* <td className="py-3 text-zinc-100 w-1/3">{technique.fields["mentor.name"][0]}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}