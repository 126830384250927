import { ItemsTable } from "components/itemsTab/ItemTable";
import React from "react";
import { Breadcrumbs } from "theplatform/breadcrumbs";

interface ItemTabProps {
};

export const ItemsTab: React.FC<ItemTabProps> = () => {

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-6">
        <Breadcrumbs />
        <ItemsTable />
      </div>
    </div>
  );
}
