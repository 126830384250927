interface MainContentProps {
  children: React.ReactNode
};

export const MainContent: React.FC<MainContentProps> = ({ children }) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col bg-contentBg w-full rounded-2xl gap-10">{children}</div>
    </div>
  );
}
