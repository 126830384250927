import React from "react";
import { ConditionalLink } from "theplatform/conditionalLink";

type ButtonProps = GenericButtonProps & (OutlineButtonProps | ClearButtonProps | FilledButtonProps)

interface GenericButtonProps {
  size?: "sm" | "md" | "lg"
  to?: string
  openInNewTab?: boolean
  children: React.ReactNode
  expand?: boolean
  format?: "round" | "square"
  color?: string
}

interface OutlineButtonProps {
  variant?: "outline"
  borderSize?: "thin" | "normal"
}

interface ClearButtonProps {
  variant?: "clear"
}

interface FilledButtonProps {
  variant?: "filled"
}



export const Button: React.FC<ButtonProps> = (props) => {
  let className = ""
  let linkClassname = ""

  switch (props.format) {
    case undefined:
    case "round":
      className += " rounded-[72px]"
      break
    case "square":
      className += " rounded-md"
      break
  }


  switch (props.variant) {
    case "outline":
      const borderSize = props.borderSize === "thin" ? "border" : "border-2"
      className += ` text-primary border-accent hover:underline ${borderSize}`
      break
    case "filled":
      className += " text-primary bg-accent hover:underline"
      break
    case undefined:
    case "clear":
      className += " text-primary hover:underline"
      break
  }

  switch (props.size) {
    case "sm":
      className += " py-1 px-2"
      break
    case "md":
      className += " py-2 px-4"
      break
    case undefined:
    case "lg":
      className += " py-4 px-8"
      break
  }

  if (props.expand) {
    className += " w-full"
    linkClassname += " w-full"
  }


  if (props.color) {
    className += ` !${props.color}`
  }


  return (
    <ConditionalLink to={props.to} openInNewTab={props.openInNewTab} className={linkClassname}>
      <button className={className}>{props.children}</button>
    </ConditionalLink>
  );
}
