import { App } from "App";
import { ItemsTab } from "components/itemsTab";
import {
  createBrowserRouter,
  redirect,
} from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        loader: () => redirect("/tips")
      },
      {
        path: "tips",
        element: <ItemsTab />
      },
    ]
  }]
);