import React, { FC } from 'react'
import { Link } from 'react-router-dom';

export type ConditionalLinkProps = {
  children: React.ReactNode;
  to?: string;
  openInNewTab?: boolean;
  className?: string
}

export const ConditionalLink: FC<ConditionalLinkProps> = ({ children, to, openInNewTab = false, className = "" }) => {
  if (!to) {
    return <>{children}</>
  }

  return (
    <Link to={to} target={openInNewTab ? "_blank" : undefined} className={className}>
      {children}
    </Link>
  )
}
