export const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const unslug = (input: string, capitalizeAll: boolean) => {
  const replaced = input.replaceAll("-", " ")
  if (!capitalizeAll) {
    return capitalize(replaced)
  }

  return replaced.split(" ").map(input => capitalize(input)).join(" ")
}

export const shortenAddress = (input: string) => {
  return input.slice(0, 4) + "..." + input.slice(input.length - 4, input.length)
}


export const apiFetcher = (url: string) => fetch(url, {
  headers: {
    'Authorization': `Bearer patAnWAacVdE1PsD0.1f5cca7a735d9b513fc29566c346ef5b08a485b9c86e23317be28d31ba0bc2ad`,
  }
}).then(res => res.json());
