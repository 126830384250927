import React from "react";
import { ConditionalLink } from "theplatform/conditionalLink";
import { unslug } from "utils";

interface BreadcrumbProps {
  label: string
  to?: string
  isFirst: boolean
  isLast: boolean
  capitalizeAll: boolean
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ label, isFirst, capitalizeAll, isLast }) => {
  const to = isLast ? undefined : `/${label}`

  return (
    <div className="flex gap-2 font-gothic text-6xl">
      {!isFirst && <div>/</div>}
      <ConditionalLink to={to}>
        <div className={to ? "underline" : ""}>{unslug(label, capitalizeAll)}</div>
      </ConditionalLink>
    </div>
  );
}
