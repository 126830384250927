import { Link, Outlet } from "react-router-dom"
import arrowFortward from "images/arrow_forward.svg"
import { Button } from "theplatform/ui/Button"
import finance from "images/finance.webp"

export const Home: React.FC<{}> = () => {
  return (
    <div className="bg-appBg text-white">
      <div className="container mx-auto px-4 flex flex-col gap-10">
        <header className="flex justify-between items-center h-28">
          <Link to="/">
            <div className="text-5xl font-bold text-accent">CR</div>
          </Link>
          <nav>
            <div>
              <Button variant="clear" size="lg" color="text-primary">
                <p className="text-base">
                  About Us
                </p>
              </Button>
              <Button variant="outline" size="lg" to="https://cashrhino.beehiiv.com/subscribe" openInNewTab>
                <p className="text-base font-bold">
                  Join Mailing List
                </p>
              </Button>
            </div>
          </nav>
        </header>

        <main className="flex flex-col py-20 gap-16">
          <div className="flex flex-col gap-8">
            <div className="w-full flex text-[19vw] md:text-[12vw] leading-[9vw] text-primary font-gothic font-semibold">
              <div className="border-b-[22px] md:border-b-[22px] border-b-accent">Cash</div>
              <div>Rhino</div>
            </div>
            <div className="flex justify-center w-full space-x-[-300px] relative">
              <img className="opacity-40" src={finance} alt="finance" />
              <div className="absolute left-0 bottom-4 flex flex-col !ml-0 gap-6 top-1 justify-between md:top-auto md:justify-normal">
                <div className="flex flex-col text-primary font-semibold md:text-3xl text-[4.5vw] leading-5 md:leading-9">
                  <div>Maximize Your Financial Growth with Proven,</div>
                  <div>Expert-Driven Investment Strategies</div>
                </div>
                <div className="flex gap-4">
                  <div className="flex w-full">
                    <Button variant="filled" size="lg" expand to="https://cashrhino.beehiiv.com/subscribe" openInNewTab>
                      <div className="flex justify-between">
                        <p className="text-base font-bold">
                          Join Mailing List
                        </p>
                        <img src={arrowFortward} alt="arrowFortward" />
                      </div>
                    </Button>
                  </div>
                  <Button variant="outline" borderSize="normal" to="https://www.youtube.com" openInNewTab>
                    <span className="text-accent">▶</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Outlet />
          <div className="h-20"></div>
        </main>
      </div>
    </div >
  )
}
